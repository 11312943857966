/* eslint perfectionist/sort-objects: "error" */
export const FINCH_ERRORS = {
  CONNECT_CALLBACK_CODE_MISSING_EMPLOYER_INFORMATION:
    'Code is missing employer information',
  CONNECT_CALLBACK_MISSING_EMPLOYER_INFORMATION:
    'Employer information is not available',
  CONNECT_CALLBACK_NO_CODE: 'Code is required to perform connection',
  CONNECT_PROCESS_FAILURE:
    'Connection process failed. Please try again or contact Zorro support if the problem persists.',
  INTEGRATION_NO: 'Integration does not exist',
  INTEGRATION_NO_ACCESS_TOKEN: 'Integration does not have access token',
  INTEGRATION_NO_ACTIVE: 'Integration is not active',
};

/* eslint perfectionist/sort-objects: "error" */
export const BENEFIT_ENROLLMENT_ERRORS = {
  EFFECTIVE_DATES_OVERLAP: 'Effective dates overlap with a later enrollment',
  EFFECTIVE_FROM_DATE_MISMATCH:
    'Effective from date must coincide with employee eligibility from date',
  EFFECTIVE_PERIOD_AFTER_ELIGIBILITY:
    'Effective period can’t start after the end of eligibility (dismissed employee)',
  EFFECTIVE_UNTIL_DATE_MISMATCH:
    'Effective until date must coincide with employee eligibility until date',
  ENROLLMENT_NOT_EXIST: 'Benefit enrollment does not exist',
  EXCEED_BOUNDS_OPEN_ENROLLMENT:
    'Unable to exceed bounds of open enrollment period effective dates',
  NO_EFFECTIVE_DATES_SPECIFIED: 'No effective dates were specified',
};

/* eslint perfectionist/sort-objects: "error" */
export const ERROR_MESSAGES = {
  ADDRESS_IS_PO_BOX_ERROR_MESSAGE:
    'Residential address cannot be a PO Box. Please enter a valid street address.',

  ADDRESS_NOT_SUPPORTED_ERROR_MESSAGE:
    'The provided address is not currently supported',

  ALLOWANCE_MODEL_IN_USE_ERROR_MESSAGE:
    'Quote saved. The allowance model is already in use and cannot be applied.',

  CONFLICTING_ENROLLMENTS_ERROR:
    'The employee already has an open enrollment for this year',

  COULD_NOT_CREATE_ACTIVATION_LINK_ERROR_MESSAGE:
    'Could not create activation link',

  COULD_NOT_SEND_INVITATION_EMAIL_ERROR_MESSAGE:
    'Could not send invitation email',

  COVERAGE_START_AFTER_WINDOW_ERROR:
    'The start date of coverage must be after the election window ends',

  DEPENDANT_OVERAGE_ERROR:
    'Dependent age must be less than 26 at enrollment target date',

  EFFECTIVE_DATE_REQUIRED_ERROR_MESSAGE: 'Effective date required',

  EFFECTIVE_OPEN_ENROLLMENT_PERIOD_NOT_FOUND_ERROR_MESSAGE:
    "Couldn't find an effective open enrollment period",

  ELECTION_WINDOW_CHANGE_AFTER_SUBMISSION_ERROR:
    "Can't change election window and expected start of coverage after election submission. Please reset election first",

  ELIGIBILITY_END_EARLIER_THAN_COVERAGE_ERROR:
    "The employee's eligibility ends before the expected start of coverage",

  ELIGIBILITY_START_DATE_MSG:
    'Eligibility start date must be the 1st of a month',

  ELIGIBILITY_START_LATER_THAN_COVERAGE_ERROR:
    "The employee's eligibility start date is later than the open enrollment coverage start date",

  ELIGIBILITY_STARTS_AFTER_START_OF_EXPECTED_COVERAGE_ERROR:
    'The expected start of coverage is before the employee becomes eligible for benefits',

  EMPLOYER_WITH_THE_SAME_TAX_ID_ALREADY_EXISTS_ERROR_MESSAGE:
    'Employer with the same taxId already exists',

  ENROLLMENT_ALREADY_EXISTS_ERROR:
    'The employee already has another enrollment with the same coverage start date',

  ENROLLMENT_ERRORS_MESSAGES: {
    EFFECTIVE_PERIOD_END_BEFORE_START:
      'The effective period must end after it begins',
    ONBOARDING_PERIOD_END_BEFORE_START:
      'The onboarding period must end after it begins',
  },

  EXISTING_BENEFITS_ERROR_MESSAGE:
    'Open enrollment periods cannot have existing benefits connected to it',

  FAMILY_UNIT_NOT_ALLOWED_ERROR_MESSAGE: 'Family unit update not allowed',

  getActiveOnboardingPeriodAlreadyExistsErrorMessage: (employeeId: string) => {
    return `There is already an active onboarding period for employee ${employeeId}`;
  },

  getAgenciesCannotBeAssignedErrorMessage: (performingAgencyName: string) => {
    return `One or more of the agencies provided cannot be assigned by ${performingAgencyName}`;
  },

  getAgencyCannotBeDeletedErrorMessage: (name: string) => {
    return `Agency ${name} could not be deleted because it has connected employers`;
  },

  getAgencyContactHasNoEmailErrorMessage: (id: string) => {
    return `No email found for agency contact: ${id}`;
  },

  getAgencyNotFoundErrorMessage: (id: string) => {
    return `Agency ${id} not found`;
  },

  getAgencyTypeCannotBeChangedErrorMessage: (name: string) => {
    return `Agency type could not be changed for ${name} because it has connected employers`;
  },

  getAgentCannotBeDeletedErrorMessage: (
    firstName: string,
    lastName: string,
    connectedEmployers: string[]
  ) => {
    return `Agent ${firstName} ${lastName} could not be deleted because it is the producer agent for: ${connectedEmployers.join(
      ', '
    )}`;
  },

  getAgentCannotEditErrorMessage: (propertyName: string) => {
    return `An agent cannot edit an agency's ${propertyName}`;
  },

  getAgentNotFoundError: (id: string) => {
    return `Agent ${id} not found`;
  },

  getAllowanceModelMismatchErrorMessage: (
    employeeId: string,
    employeeAge: number,
    familyUnit: string,
    employeeClass: string
  ) => {
    return `Allowance model does not match properties of employee ${employeeId}: Age: ${employeeAge}, Family unit: ${familyUnit}, Class: ${employeeClass}`;
  },

  getBenefitNotFoundErrorMessage: (benefitId: string) => {
    return `Benefit ${benefitId} not found`;
  },

  getCannotResarttEligibilityErrorMessage: (employeeName: string) => {
    return `Cannot restart eligibility for ${employeeName} because current eligibility data is incomplete.`;
  },

  getCensusPlansDoNotMatchErrorMessage: (planNames: string[]) => {
    return `${planNames.join(
      ', '
    )} are not listed in any of the census employees`;
  },

  getCouldNotCreateOneOrMoreUsersErrorMessage: () => {
    return 'Could not create one or more users';
  },

  getEmployeeAlreadyExistsErrorMessage: (email: string) => {
    return `Employee with email: ${email} already exists`;
  },

  getEmployeeCountNotFoundErrorMessage: (employerId: string) => {
    return `Could not find the total employee count for employer with id ${employerId}`;
  },
  getEmployeeHasEnrollmentDataErrorMessage: (employeeId: string) => {
    return `Employee ${employeeId} cannot be deleted because they have existing enrollments. Please contact support for assistance`;
  },

  getEmployeeNotFoundErrorMessage: (id: string) => {
    return `Employee with id ${id} not found`;
  },

  getEmployerContactHasNoEmailErrorMessage: (id: string) => {
    return `No email found for employer contact: ${id}`;
  },

  getEmployerHasEmployeesErrorMessage: (employerId: string) => {
    return `Employer ${employerId} should have no employees prior to deletion`;
  },

  getEmployerNotFoundErrorMessage: (employerId: string) => {
    return `Employer with id ${employerId} not found`;
  },

  getEntityNotFoundErrorMessage: (id: string) => {
    return `Entity ${id} not found`;
  },

  getFileFormatErrorMessage: (message: string) => {
    return `Something went wrong, possibly the file is not of the right format: \n${message}`;
  },

  getInsufficientCensusDataErrorMessage: (quoteId: string) => {
    return `The census data is insufficient to create a quote for: ${quoteId}`;
  },

  getInsuredNotFoundErrorMessage: (periodId: string) => {
    return `Insured people not found for onboarding period ${periodId}`;
  },

  getInvalidAddressErrorMessage: (address: string, reason?: string) => {
    return `Could not validate '${address}': ${reason ?? 'unexpected error'}`;
  },

  getInvalidBenefitsErrorMessage: (onboardingPeriodId: string) => {
    return `More than one benefit of the same type was found for onboarding period: ${onboardingPeriodId}`;
  },

  getInvalidChildCountErrorMessage: (employeeId: string) => {
    return `Invalid child count for employee ${employeeId}`;
  },

  getInvalidDataAtRowWithDetailsErrorMessage: (
    index: number,
    plan: {
      id: string;
      carrier: string;
      type: string;
    }
  ) => {
    return `[plan-diversity] Invalid data at row ${index}: ${JSON.stringify(
      plan
    )}`;
  },

  getInvalidDataAtRowWithPlanDataErrorMessage: (index: number) => {
    return `[plan-data] Invalid data at row ${index}`;
  },

  getInvalidFamilyUnitErrorMessage: (familyUnit: string) => {
    return `[scenarios] Invalid family unit: ${familyUnit}`;
  },

  getInvalidMedicalPlanFilterErrorMessage: (
    filterExpression: string,
    message: string
  ) => {
    return `Medical plan filter "${filterExpression}" is invalid: ${message}`;
  },

  getInvalidPersonalDetailErrorMessage: (
    personalDetailKey: string,
    personalDetailValue: string,
    extra?: string
  ) => {
    return [
      `Invalid ${personalDetailKey} : '${personalDetailValue}'`,
      extra,
    ].join('. ');
  },

  getMajorMedicalPlanNotSelfReportedErrorMessage: (benefitId: string) => {
    return `Major medical plan ${benefitId} is not self-reported`;
  },

  getNoAllowanceFoundErrorMessage: (employeeId: string) => {
    return `No allowance found for employee: ${employeeId}`;
  },

  getNoOpenEnrollmentPeriodsFoundErrorMessage: (employerId: string) => {
    return `Open Enrollment Period for employer ${employerId} not found`;
  },

  getNoZipCodeFoundErrorMessage: (employeeId: string) => {
    return `No zip code found for employee ${employeeId}`;
  },

  getOnboardingPeriodNotFoundErrorMessage: (id: string) => {
    return `Onboarding Period ${id} not found`;
  },

  getOpenEnrollmentPeriodNotFoundErrorMessage: (
    openEnrollmentPeriodId: string
  ) => {
    return `Open Enrollment Period ${openEnrollmentPeriodId} not found`;
  },

  getPlanPricingZipNotFoundErrorMessage: (
    zipCode: string,
    planId: string,
    planYear: number
  ) => {
    return `Plan pricing not found for zip code: ${zipCode}, plan id: ${planId} and plan year: ${planYear}`;
  },

  getPlanSearchDataMissingErrorMessage: (missingField: string) => {
    return `${missingField} is required for plan search`;
  },

  getQuoteNotFoundErrorMessage: (quoteId: string) => {
    return `Quote not found: ${quoteId}`;
  },

  getQuotePreferenceNotFoundErrorMessage: (quoteId: string) => {
    return `Quote preferences not found: ${quoteId}`;
  },

  getRatingAreaNotFoundErrorMessage: (fipsCode: string, zipCode: string) => {
    return `Rating area not found for fips code: ${fipsCode} and zip code: ${zipCode}`;
  },

  getRoleNotFoundErrorMessage: (
    newRole: string,
    roleToIdMap: Record<string, string>
  ) => {
    return `Role with key ${newRole} not found in Frontegg roles: ${JSON.stringify(
      Object.keys(roleToIdMap)
    )}`;
  },

  getScenariosInvalidDataAtRowErrorMessage: (index: number) => {
    return `[scenarios] Invalid data at row ${index}`;
  },

  getUnexpectedQuoteProcessingErrorMessage: () => {
    return 'Could not generate a valid quote. Please retry later or contact support if the issue persists';
  },

  getUnrecognizedContactTypeErrorMessage: (contactType: string) => {
    return `Contact type ${contactType} not recognized`;
  },

  getUserExistsError: (email: string) => {
    return `A user with email ${email} already exists`;
  },

  getUserNotFoundError: (email: string) => {
    return `User ${email}not found`;
  },

  getUserSetupIncompleteErrorMessage: (email: string) => {
    return `User with email ${email} has not completed the setup and has no name yet`;
  },

  HIRE_DATE_REQUIRED_MSG:
    'Hire date is a required field. Please include it in your input',

  INSURED_UNDERAGE_ERROR:
    'Insured age must be over 18 at enrollment target date',

  LIFE_EVENT_DATE_RANGE_ERROR_MESSAGE:
    'Qualifying Life Event occurredOn date is out of date range',

  LIFE_EVENT_OTHER_REASON_ERROR_MESSAGE:
    'Qualifying Life Event other type must have other reason',

  MEDICAL_BENEFIT_SUBMISSION_ERROR_MESSAGE:
    'could not submit a plan for the major medical benefit',

  MEDICAL_PLAN_NOT_FOUND_ERROR_MESSAGE: 'Medical plan not found',

  NO_DATA_FOUND_IN_THE_FILE_ERROR_MESSAGE: 'No data found in the file',

  NO_FILE_SELECTED_ERROR_MESSAGE: 'No file selected',

  NO_REQUEST_CONTEXT_ERROR_MESSAGE:
    'No http request context found, are you running a cron job? use m2mClient instead',

  OnboardingPeriodWithStartDateAlreadyExistsErrorMessage:
    'An onboarding period with the same start date already exists for employee',

  OPEN_ENROLLMENT_INVALID_START_DATE_ERROR:
    'Coverage must begin on the first day of the plan year for open enrollment',

  OVERLAPPING_PERIOD_ERROR_MESSAGE: 'Open enrollment periods cannot overlap',

  SELF_PAY_AMOUNT_TOO_LARGE_ERROR_MESSAGE:
    'Self pay amount cannot be larger than the premium amount',

  START_OF_COVERAGE_NOT_WITHIN_COVERAGE_PERIOD_ERROR:
    'The expected start of coverage must fall within the plan year’s coverage period',

  SUBMISSION_DEADLINE_MISSED_ERROR_MESSAGE:
    'Submission Deadline Missed: It’s now too late to submit this life event. For assistance, please contact support',

  TOKEN_CLIENT_NOT_INITIALIZED_ERROR_MESSAGE: 'Token client not initialized',

  UPLOADING_EMPLOYER_LOGO_FAILED_ERROR_MESSAGE:
    'Uploading employer logo failed',
};
