// @see https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const TZ_PST8PDT = 'America/Los_Angeles';

export const EN_LOCALE_DATE_FORMAT = 'MM/DD/YYYY';
export const EN_LOCALE_DATE_SHORT_YEAR_FORMAT = 'MM/DD/YY';

export const EN_LOCALE_DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm A';

export const MONTH_YEAR_EN_LOCALE_DATE_FORMAT = 'MMM YYYY';

export const MONTH_DAY_EN_LOCALE_DATE_FORMAT = 'MMM DD';

export const DAY_MONTH_DATE_FORMAT = 'D MMMM';

export const DAY_MONTH_YEAR_DATE_FORMAT = 'D MMMM YYYY';

export const MONTH_DATE_FORMAT = 'MMM';

// @see https://en.wikipedia.org/wiki/ISO_8601#RFCs
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
// @see https://en.wikipedia.org/wiki/ISO_8601#RFCs
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format
export const NATIVE_DATE_ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const PERMISSIVE_EN_LOCALE_DATE_FORMATS_ARRAY = (() => {
  const components = [
    ['M', 'MM', 'MMM'],
    ['D', 'DD'],
    ['YY', 'YYYY'],
  ];
  const separators = ['/', '-', ' ', '.'];

  return components[0].flatMap((month) =>
    components[1].flatMap((day) =>
      components[2].flatMap((year) =>
        separators.map((separator) => [month, day, year].join(separator))
      )
    )
  );
})();
