import { readLocalStorageValue, useLocalStorage } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import { OpenEnrollmentPeriodDto } from '@zorro/clients';
import { getNow, parseDateISO } from '@zorro/shared/formatters';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  ActionIcon,
  Group,
  Icon,
  RadixMenu,
  Title,
  brand,
} from '@zorro/zorro-ui-design';
import { useEffect, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

type Props = {
  titlePrefix?: string | null;
  employerId: string;
  onSelect?: (period: OpenEnrollmentPeriodDto) => void;
};

export const PlanYearTitle = ({ titlePrefix, employerId, onSelect }: Props) => {
  const storedOpenEnrollmentPeriodId = readLocalStorageValue<string>({
    key: `selectedOpenEnrollmentPeriodId_${employerId}`,
    defaultValue: '',
  });

  const [openEnrollmentPeriodQuery, setOpenEnrollmentPeriodQuery] =
    useQueryParam('enrollmentPeriodId', withDefault(StringParam, ''));

  const [, setEnrollmentPeriodLocal] = useLocalStorage({
    key: `selectedOpenEnrollmentPeriodId_${employerId}`,
    defaultValue: storedOpenEnrollmentPeriodId,
  });

  const [selectedOpenEnrollmentPeriod, setSelectedOpenEnrollmentPeriod] =
    useState<OpenEnrollmentPeriodDto | null>(null);

  const { data: openEnrollmentPeriods = [] } = useMonolithQuery({
    method: 'openEnrollmentPeriodsControllerFindAllForEmployer',
    params: [employerId],
  });

  const selectedPlanYear = selectedOpenEnrollmentPeriod?.effectiveFrom
    ? parseDateISO(selectedOpenEnrollmentPeriod.effectiveFrom).year().toString()
    : null;

  useEffect(() => {
    if (openEnrollmentPeriodQuery) {
      const selectedOEP = openEnrollmentPeriods.find(
        (oep) => oep.id === openEnrollmentPeriodQuery
      );
      if (selectedOEP) {
        setSelectedOpenEnrollmentPeriod(selectedOEP);
        setEnrollmentPeriodLocal(selectedOEP.id);
      }
    } else if (storedOpenEnrollmentPeriodId) {
      const selectedOEP = openEnrollmentPeriods.find(
        (oep) => oep.id === storedOpenEnrollmentPeriodId
      );
      if (selectedOEP) {
        setSelectedOpenEnrollmentPeriod(selectedOEP);
        setOpenEnrollmentPeriodQuery(selectedOEP.id);
      }
    } else if (
      selectedOpenEnrollmentPeriod === null &&
      openEnrollmentPeriods.length > 0
    ) {
      const currentOEP = openEnrollmentPeriods.find((oep) =>
        getNow().isBetween(
          parseDateISO(oep.effectiveFrom),
          parseDateISO(oep.effectiveUntil)
        )
      );
      if (currentOEP) {
        setSelectedOpenEnrollmentPeriod(currentOEP);
        onSelect?.(currentOEP);
      } else {
        const latestOEP = openEnrollmentPeriods.sort(
          (prev, curr) =>
            parseDateISO(curr.effectiveFrom).year() -
            parseDateISO(prev.effectiveFrom).year()
        )[0];
        setSelectedOpenEnrollmentPeriod(latestOEP);
        onSelect?.(latestOEP);
      }
    }
  }, [
    selectedOpenEnrollmentPeriod,
    openEnrollmentPeriods,
    onSelect,
    storedOpenEnrollmentPeriodId,
    openEnrollmentPeriodQuery,
    setEnrollmentPeriodLocal,
    setOpenEnrollmentPeriodQuery,
  ]);

  return (
    <Group gap="xs">
      <Title>{`${titlePrefix ?? ''}${
        selectedPlanYear ? ` ${selectedPlanYear}` : ''
      }`}</Title>
      {openEnrollmentPeriods.length > 1 && (
        <RadixMenu>
          <RadixMenu.Trigger>
            <ActionIcon>
              <Icon icon={IconChevronDown} color={brand.zorroGray800} />
            </ActionIcon>
          </RadixMenu.Trigger>
          <RadixMenu.Content align="start">
            {openEnrollmentPeriods.map((period) => (
              <RadixMenu.Item
                key={period.id}
                onClick={() => {
                  onSelect?.(period);
                  setSelectedOpenEnrollmentPeriod(period);
                }}
              >
                {parseDateISO(period.effectiveFrom).year()}
              </RadixMenu.Item>
            ))}
          </RadixMenu.Content>
        </RadixMenu>
      )}
    </Group>
  );
};
