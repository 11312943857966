import { AppShell } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { Box, brand } from '@zorro/zorro-ui-design';
import { ReactNode, forwardRef } from 'react';

import { useAppLayout } from '../AppLayoutContext';
import { AppLayoutConstants } from '../AppLayoutUtils';

type Props = {
  children: ReactNode;
  topLeftLabel?: string;
};

export const AppLayoutMain = forwardRef<HTMLDivElement, Props>(
  ({ children, topLeftLabel }: Props, ref) => {
    const { height: viewportHeight } = useViewportSize();
    const { isSidebarOpen } = useAppLayout();

    return (
      <AppShell.Main
        style={{
          height: `${viewportHeight - AppLayoutConstants.HEADER_HEIGHT}px`,
        }}
      >
        <Box ref={ref}>
          <Box pos="relative">
            {!isSidebarOpen && topLeftLabel && (
              <Box
                pos="absolute"
                left={0}
                top={0}
                bg={brand.zorroPlum200}
                p="0.375rem 2.5rem"
                fz={14}
                fw={400}
                style={{ borderBottomRightRadius: 16 }}
              >
                {topLeftLabel}
              </Box>
            )}
            <Box>{children}</Box>
          </Box>
        </Box>
      </AppShell.Main>
    );
  }
);

AppLayoutMain.displayName = 'AppLayoutMain';
