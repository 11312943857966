import { IconSettings } from '@tabler/icons-react';
import {
  ZORRO_UI_DYNAMIC_ROUTES,
  createImpersonationSession,
  useRoles,
} from '@zorro/shared/utils';
import { RadixMenu } from '@zorro/zorro-ui-design';

import { ActionsSubMenu } from '../ActionsSubMenu';
import { EmployeeActionsOverlays } from '../employeeActions.consts';
import { ActionsMenuSection } from '../employeeActions.types';
import { resetPassword } from './accountManagement.utils';

const AccountManagementSection = ({
  setOverlayId,
  selectedEmployees,
}: ActionsMenuSection) => {
  const { isZorroOperations } = useRoles();

  const isBulk = selectedEmployees.length > 1;

  return (
    <ActionsSubMenu title="Account management" icon={IconSettings}>
      <RadixMenu.Item
        onClick={() =>
          setOverlayId(EmployeeActionsOverlays.CHANGE_EMPLOYEE_ROLE)
        }
      >
        {EmployeeActionsOverlays.CHANGE_EMPLOYEE_ROLE}
      </RadixMenu.Item>
      {!isBulk && (
        <>
          <RadixMenu.Item
            onClick={() => {
              createImpersonationSession(
                selectedEmployees[0].id,
                ZORRO_UI_DYNAMIC_ROUTES.MY_COVERAGE()
              );
            }}
          >
            Log in as
          </RadixMenu.Item>
          {isZorroOperations && (
            <RadixMenu.Item
              onClick={async () => {
                await resetPassword(selectedEmployees[0].userId);
              }}
            >
              Reset password
            </RadixMenu.Item>
          )}
          <RadixMenu.Item
            onClick={() =>
              setOverlayId(EmployeeActionsOverlays.EDIT_COMPANY_EMAIL)
            }
          >
            Edit company email
          </RadixMenu.Item>
        </>
      )}
      <RadixMenu.Item
        onClick={() => setOverlayId(EmployeeActionsOverlays.DELETE_EMPLOYEE)}
      >
        {isBulk ? 'Delete employees' : 'Delete employee'}
      </RadixMenu.Item>
    </ActionsSubMenu>
  );
};

export { AccountManagementSection };
