import { FinchConnectionStatusType } from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import { Button, Center, Group, Loader, Text } from '@zorro/zorro-ui-design';

import { useMonolithMutation } from '../hooks';
import { FinchConnectionCard } from './FinchConnectionCard';
import { FinchDisconnect } from './FinchDisconnect';
import { FinchEmployeesList } from './FinchEmployeesList';
import { FinchIntroduction } from './FinchIntroduction';
import { FinchPaymentsList } from './FinchPaymentsList';

type Props = {
  employerId: string;
};

export const FinchIntegrationManagement = ({ employerId }: Props) => {
  const { tryMutate } = useMonolithMutation({
    method: 'finchControllerSyncCompany',
  });

  const {
    isLoading: isLoadingConnectStatus,
    data: connectStatus,
    isError,
    error,
  } = useMonolithQuery({
    method: 'finchControllerConnectStatus',
    params: [{ employerId }],
  });

  if (isLoadingConnectStatus) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  // @ts-expect-error error type is unknown
  if (isError && error.status === 404) {
    return <FinchIntroduction employerId={employerId} />;
  }

  if (isError && error) {
    return <Text>Unexpected error</Text>;
  }

  if (!connectStatus) {
    return <Text>No integration status is available</Text>;
  }

  if (
    connectStatus.connectionStatus?.status ===
    FinchConnectionStatusType.CONNECTED
  ) {
    return (
      <>
        <FinchConnectionCard {...connectStatus} />
        <Group align="start" wrap="nowrap">
          <FinchPaymentsList employerId={employerId} />
          <FinchEmployeesList employerId={employerId} />
        </Group>
        <Group>
          <Button size="lg" onClick={async () => await tryMutate([employerId])}>
            Sync company
          </Button>
          <FinchDisconnect employerId={employerId} />
        </Group>
      </>
    );
  }

  return <FinchIntroduction employerId={employerId} />;
};
