import { IconCalendarMonth, IconInfoCircle } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { SubmissionType } from '@zorro/clients';
import { formatDateISO } from '@zorro/shared/formatters';
import {
  KeyedError,
  callEndpoint,
  getErrorsFromPromises,
  handleBatchNotifications,
  showErrorNotification,
  useDynamicRouter,
} from '@zorro/shared/utils';
import { Divider, Group, Icon, Stack, Text } from '@zorro/zorro-ui-design';
import _chunk from 'lodash/chunk';
import { useState } from 'react';

import {
  EmployeeRow,
  ErrorsTable,
  FormFooter,
  useLoadingOverlay,
} from '../../../index';
import { getWaiveCoverageEffectiveDates } from './waiveCoverage.utils';

type WaiveCoverageFormBulkProps = {
  selectedEmployees: Pick<
    EmployeeRow,
    'id' | 'onboardingPeriodId' | 'fullName'
  >[];
  onSuccess: () => void;
  onClose: () => void;
};

const WaiveCoverageFormBulk = ({
  selectedEmployees,
  onSuccess,
  onClose,
}: WaiveCoverageFormBulkProps) => {
  const queryClient = useQueryClient();
  const { reloadPage } = useDynamicRouter({});
  const { startLoading, stopLoading } = useLoadingOverlay();

  const [errors, setErrors] = useState<KeyedError[]>([]);

  async function handleSubmit() {
    try {
      setErrors([]);
      startLoading();

      const employeeBatches = _chunk(selectedEmployees, 5);

      const results: PromiseSettledResult<unknown>[] = [];

      for (const batch of employeeBatches) {
        const batchResults = await Promise.allSettled(
          batch.map(async ({ fullName, id, onboardingPeriodId }) => {
            if (!onboardingPeriodId) {
              throw new Error(`${fullName} doesn't have an onboarding period`);
            }

            const [employee, onboardingPeriod, benefits] = await Promise.all([
              callEndpoint({
                method: 'employeesControllerFindOne',
                params: [id],
              }),
              callEndpoint({
                method: 'onboardingPeriodsControllerFindOne',
                params: [id, onboardingPeriodId],
              }),
              callEndpoint({
                method: 'benefitsControllerGetBenefits',
                params: [onboardingPeriodId],
              }),
            ]);

            const [effectiveFrom, effectiveUntil] =
              getWaiveCoverageEffectiveDates(
                employee,
                onboardingPeriod,
                benefits.majorMedicalBenefit?.benefitEnrollment,
                SubmissionType.BY_OPERATOR
              );

            if (!effectiveFrom || !effectiveUntil) {
              throw new Error(
                `Could not calculate waiver effective dates for ${fullName}`
              );
            }

            return callEndpoint({
              method: 'benefitsControllerWaiveCoverage',
              params: [
                onboardingPeriodId,
                {
                  effectiveDates: {
                    from: formatDateISO(effectiveFrom),
                    until: formatDateISO(effectiveUntil),
                  },
                  submissionType: SubmissionType.BY_OPERATOR,
                },
              ],
            });
          })
        );

        results.push(...batchResults);
      }

      await queryClient.invalidateQueries();
      reloadPage();

      const errorsFromPromises = getErrorsFromPromises(
        results,
        selectedEmployees.map(({ fullName }) => ({ key: fullName }))
      );

      setErrors(errorsFromPromises);

      await handleBatchNotifications(
        results,
        { singular: 'employee' },
        'updated'
      );

      if (errorsFromPromises.length === 0) {
        onSuccess();
      }
    } catch {
      showErrorNotification({ message: 'Failed to update employees' });
    } finally {
      stopLoading();
    }
  }

  return (
    <>
      <Stack>
        <Group wrap="nowrap" gap="xs">
          <IconCalendarMonth />
          <Text>Waiver effective dates</Text>
        </Group>
        <Text>
          Each employee's waiver will start from their expected coverage date
          and last until the end of the plan year
        </Text>
        <Divider my="md" />
        <Group wrap="nowrap" gap="xs">
          <Icon icon={IconInfoCircle} />
          <Text>
            Please make sure to collect evidence to each employee's waive
            election confirmation
          </Text>
        </Group>

        <ErrorsTable
          tableTitle="Employee name"
          errors={errors}
          isBulk={selectedEmployees?.length > 1}
        />
      </Stack>

      <FormFooter
        primaryLabel="Submit waiver"
        primaryButtonProps={{ onClick: handleSubmit }}
        secondaryLabel="Cancel"
        secondaryButtonProps={{
          onClick: onClose,
        }}
      />
    </>
  );
};

export { WaiveCoverageFormBulk };
