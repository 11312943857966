import { IconDotsVertical } from '@tabler/icons-react';
import { ActionIcon, RadixMenu } from '@zorro/zorro-ui-design';
import { useState } from 'react';

export type EditSubMenuItem = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
};

export type EditSubMenuProps = {
  items: EditSubMenuItem[];
};

export const EditSubMenu = ({ items }: EditSubMenuProps) => {
  const [open, setOpen] = useState(false);

  return (
    <RadixMenu open={open} onOpenChange={setOpen}>
      <RadixMenu.Trigger>
        <ActionIcon isChecked={open} isTransparent>
          <IconDotsVertical />
        </ActionIcon>
      </RadixMenu.Trigger>
      <RadixMenu.Content style={{ width: 200 }} align="end">
        {items.map((item) => (
          <RadixMenu.Item
            key={item.title}
            onClick={item.onClick}
            disabled={item.disabled}
          >
            {item.title}
          </RadixMenu.Item>
        ))}
      </RadixMenu.Content>
    </RadixMenu>
  );
};
