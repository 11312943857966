import { IconCopy } from '@tabler/icons-react';
import { PaymentDirectACHDto } from '@zorro/clients';
import { IconZorroBankBuilding } from '@zorro/shared/assets';
import {
  ActionIcon,
  Card,
  Flex,
  Group,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { PAYMENT_METHOD_DIRECT_ACH_CONTAINER_WIDTH } from '../paymentsDesign.consts';
import { getSensitivePaymentData } from '../paymentsPciDssMasking.utils';

type PaymentMethodDirectACHProps = PaymentDirectACHDto & {
  isMasked: boolean;
};

const PaymentMethodDirectACH = ({
  accountNumber,
  bankName,
  name,
  routingNumber,
  bankAddress,
  accountType,
  isMasked,
}: PaymentMethodDirectACHProps) => {
  const formattedAccountNumber = getSensitivePaymentData(
    accountNumber,
    isMasked
  );
  const formattedRoutingNumber = getSensitivePaymentData(
    routingNumber,
    isMasked
  );

  return (
    <Card
      px="md"
      py="lg"
      mb="md"
      style={{ maxWidth: PAYMENT_METHOD_DIRECT_ACH_CONTAINER_WIDTH }}
    >
      <Flex columnGap="md" align="flex-start" wrap="nowrap">
        <IconZorroBankBuilding />
        <Flex columnGap="xl" wrap="nowrap" style={{ width: '100%' }}>
          <Stack flex="1 1 50%" gap="md">
            <Stack gap={0}>
              <Text size="sm">Account number</Text>
              <Group wrap="nowrap">
                <Text fw="500" className="fs-exclude">
                  {formattedAccountNumber}
                </Text>
                {!isMasked && (
                  <CopyToClipboard text={accountNumber}>
                    <ActionIcon size="sm">
                      <IconCopy stroke={1} size={20} cursor="pointer" />
                    </ActionIcon>
                  </CopyToClipboard>
                )}
              </Group>
            </Stack>

            <Stack gap={0}>
              <Text size="sm">Bank name</Text>
              <Text fw="500">{bankName}</Text>
            </Stack>

            <Stack gap={0}>
              <Text size="sm">Name on account</Text>
              <Text fw="500">{name}</Text>
            </Stack>
          </Stack>
          <Stack flex="1 1 50%" gap="md">
            <Stack gap={0}>
              <Text size="sm">Routing number</Text>
              <Group wrap="nowrap">
                <Text fw="500" className="fs-exclude">
                  {formattedRoutingNumber}
                </Text>
                {!isMasked && (
                  <CopyToClipboard text={routingNumber}>
                    <ActionIcon size="sm">
                      <IconCopy stroke={1} size={20} cursor="pointer" />
                    </ActionIcon>
                  </CopyToClipboard>
                )}
              </Group>
            </Stack>

            <Stack gap={0}>
              <Text size="sm">Bank address</Text>
              <Text fw="500">{bankAddress}</Text>
            </Stack>

            <Stack gap={0}>
              <Text size="sm">Account type</Text>
              <Text fw="500">{accountType}</Text>
            </Stack>
          </Stack>
        </Flex>
      </Flex>
    </Card>
  );
};

export { PaymentMethodDirectACH };
