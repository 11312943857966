import { yupResolver } from '@hookform/resolvers/yup';
import { EmployerDto, OnboardingType, SubmissionType } from '@zorro/clients';
import { formatDateISO, parseDateISO } from '@zorro/shared/formatters';
import {
  UNEXPECTED_ERROR_MESSAGE,
  showErrorNotification,
  useForm,
  useMonolithQuery,
} from '@zorro/shared/utils';

import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';
import { ChangeEnrollmentForm } from './ChangeEnrollmentForm';
import {
  ChangeEnrollmentsFormFields,
  FullChangeEnrollmentSchema,
} from './changeEnrollment.utils';

export interface EditEnrollmentsDrawerProps {
  employerId: EmployerDto['id'];
  selectedEmployees: {
    employeeId: string;
    firstName: string;
    lastName: string;
    onboardingPeriodId: string;
  }[];
  onSuccess: () => void;
}

export const EditEnrollmentsRecord = ({
  employerId,
  selectedEmployees,
  onSuccess,
}: EditEnrollmentsDrawerProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;

  const { data: allOpenEnrollmentPeriods = [] } = useMonolithQuery({
    method: 'openEnrollmentPeriodsControllerFindAllForEmployer',
    params: [employerId],
  });

  const { data: associatedOnboardingPeriodsSingleEmployee } = useMonolithQuery({
    method: 'onboardingPeriodsControllerFindMany',
    params: [selectedEmployees[0]?.employeeId],
    enabled: isSingleEmployee,
  });

  const associatedOtherOnboardingPeriodsSingleEmployee =
    associatedOnboardingPeriodsSingleEmployee?.filter(
      (onboardingPeriod) =>
        onboardingPeriod.id !== selectedEmployees[0].onboardingPeriodId
    );

  const { data: singleEmployeeDto } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [selectedEmployees[0]?.employeeId],
    enabled: isSingleEmployee,
  });

  const {
    executeBatchCall: updateEnrollments,
    errors: updateEnrollmentsErrors,
  } = useBatchCallEndpoint({
    singularItemName: 'enrollment record',
    action: 'updated',
    methodName: 'onboardingPeriodsControllerUpdateByAdmin',
  });

  const singleEmployeeOnboardingPeriodForDefaultValues =
    associatedOnboardingPeriodsSingleEmployee
      ? associatedOnboardingPeriodsSingleEmployee.find(
          (period) => period.id === selectedEmployees[0].onboardingPeriodId
        )
      : undefined;

  const editModeSingleEmployeeDefaultValues:
    | ChangeEnrollmentsFormFields
    | undefined = singleEmployeeOnboardingPeriodForDefaultValues
    ? {
        planYear:
          singleEmployeeOnboardingPeriodForDefaultValues.enrollmentPeriodId,
        enrollmentType:
          singleEmployeeOnboardingPeriodForDefaultValues.isSpecialEnrollment
            ? OnboardingType.SPECIAL
            : OnboardingType.OPEN_ENROLLMENT,
        electionWindow: [
          parseDateISO(
            singleEmployeeOnboardingPeriodForDefaultValues.onboardingFrom
          ).toDate(),
          parseDateISO(
            singleEmployeeOnboardingPeriodForDefaultValues.onboardingUntil
          ).toDate(),
        ],
        expectedStartOfCoverage: parseDateISO(
          singleEmployeeOnboardingPeriodForDefaultValues.targetEnrollmentDate
        ).toDate(),
      }
    : undefined;

  const form = useForm<ChangeEnrollmentsFormFields>({
    mode: 'all',
    resolver: yupResolver(FullChangeEnrollmentSchema),
    defaultValues: isSingleEmployee
      ? editModeSingleEmployeeDefaultValues
      : undefined, // no default values for bulk edit mode
  });

  const submitEditEnrollment = async (data: ChangeEnrollmentsFormFields) => {
    const {
      planYear: enrollmentPeriodId,
      enrollmentType,
      electionWindow,
      expectedStartOfCoverage,
    } = data;
    const [electionFrom, electionUntil] = electionWindow || [];

    if (!electionFrom || !electionUntil || !expectedStartOfCoverage) {
      showErrorNotification({ message: UNEXPECTED_ERROR_MESSAGE });
      return;
    }

    const enrollmentData = {
      enrollmentPeriodId,
      isSpecialEnrollment: enrollmentType === OnboardingType.SPECIAL,
      onboardingFrom: formatDateISO(electionFrom),
      onboardingUntil: formatDateISO(electionUntil),
      expectedStartOfCoverage: formatDateISO(expectedStartOfCoverage),
    };

    await updateEnrollments(
      selectedEmployees.map((employee) => ({
        key: `${employee.firstName} ${employee.lastName}`,
        params: [
          employee.onboardingPeriodId,
          {
            ...enrollmentData,
            submissionType: SubmissionType.BY_OPERATOR,
          },
        ],
      })),
      { onSuccess }
    );
  };

  return (
    <>
      <ChangeEnrollmentForm
        form={form}
        onSubmit={submitEditEnrollment}
        allOpenEnrollmentPeriods={allOpenEnrollmentPeriods}
        singleEmployeeDto={singleEmployeeDto}
        associatedOtherOnboardingPeriodsSingleEmployee={
          associatedOtherOnboardingPeriodsSingleEmployee
        }
      />

      <ErrorsTable
        tableTitle="Employee name"
        errors={updateEnrollmentsErrors}
        isBulk={selectedEmployees?.length > 1}
      />
    </>
  );
};
