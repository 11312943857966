import { UserStatus } from '@zorro/clients';
import { DeepReadonly, ValueOf } from '@zorro/types';
import { Badge, brand } from '@zorro/zorro-ui-design';

export const agentBadgeConfig: DeepReadonly<{
  [status in UserStatus]: {
    backgroundColor: ValueOf<typeof brand>;
    label: string;
  };
}> = {
  [UserStatus.NOT_ACTIVATED]: {
    backgroundColor: brand.zorroFire300,
    label: 'Not activated',
  },
  [UserStatus.PENDING_LOGIN]: {
    backgroundColor: brand.zorroYolk200,
    label: 'Not invited',
  },
  [UserStatus.PENDING_INVITATION]: {
    backgroundColor: brand.primaryButtonSelected,
    label: 'Invited',
  },
  [UserStatus.ACTIVATED]: {
    backgroundColor: brand.zorroGreen300,
    label: 'Activated',
  },
};

type Props = {
  status: UserStatus;
};

export const AgentStatusBadge = ({ status }: Props) => {
  const { backgroundColor, label } =
    agentBadgeConfig[status ?? UserStatus.PENDING_LOGIN];

  return (
    <Badge
      c="zorroCoal.0"
      variant="light"
      bg={backgroundColor}
      h={32}
      fz={14}
      p="5 12px"
    >
      {label}
    </Badge>
  );
};
