import {
  AgentIcon,
  EmployeeIcon,
  IconZorroPaymentCard,
  IconZorroPaymentCombined,
  IconZorroPaymentSelfPay,
} from '@zorro/shared/assets';
import { brand } from '@zorro/zorro-ui-design';

type IconProps = {
  size?: number;
};

export function PaymentMethodZorroPayIcon({ size = 32 }: IconProps) {
  return (
    <IconZorroPaymentCard
      style={{
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: brand.primaryButtonSelected,
        padding: '5px',
      }}
    />
  );
}

export function PaymentMethodSelfPayIcon({ size = 32 }: IconProps) {
  return (
    <IconZorroPaymentSelfPay
      style={{
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: brand.zorroYolk200,
        padding: '5px',
      }}
    />
  );
}

export function PaymentMethodCombinedPayIcon({ size = 32 }: IconProps) {
  return (
    <IconZorroPaymentCombined
      style={{
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: brand.zorroSky100,
        padding: '5px',
      }}
    />
  );
}

export function PaymentStatusTrueAgentIcon({ size = 32 }: IconProps) {
  return (
    <AgentIcon
      style={{
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: brand.zorroGreen300,
        padding: '5px',
      }}
    />
  );
}

export function PaymentStatusFalseAgentIcon({ size = 32 }: IconProps) {
  return (
    <AgentIcon
      style={{
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: brand.zorroFire300,
        padding: '5px',
      }}
    />
  );
}

export function PaymentStatusTrueEmployeeIcon({ size = 32 }: IconProps) {
  return (
    <EmployeeIcon
      style={{
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: brand.zorroGreen300,
        padding: '5px',
      }}
    />
  );
}

export function PaymentStatusFalseEmployeeIcon({ size = 32 }: IconProps) {
  return (
    <EmployeeIcon
      style={{
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: brand.zorroFire300,
        padding: '5px',
      }}
    />
  );
}

export function PaymentStatusUnknownEmployeeIcon({ size = 32 }: IconProps) {
  return (
    <EmployeeIcon
      style={{
        borderRadius: '50%',
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor: brand.zorroYolk400,
        padding: '5px',
      }}
    />
  );
}
