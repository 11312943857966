import { yupResolver } from '@hookform/resolvers/yup';
import { EmployeeDto, GetEmployerDto } from '@zorro/clients';
import { useForm } from '@zorro/shared/utils';
import { Stack } from '@zorro/zorro-ui-design';

import { EmployeeClassInput } from '../../EmployeeClassInput/EmployeeClassInput';
import { EmployeeRow } from '../../EmployeesDatatable';
import { FormFooter } from '../../FormFooter';
import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';
import {
  ChangeEmployeeClassFormFields,
  changeEmployeeClassSchema,
} from './changeEmployeeClassForm.utils';

type ChangeEmployeeClassFormProps = {
  selectedEmployees: EmployeeRow[] | EmployeeDto[];
  employerId: GetEmployerDto['id'];
  onClose: () => void;
  onSuccess: () => void;
};

const ChangeEmployeeClassForm = ({
  selectedEmployees,
  employerId,
  onClose,
  onSuccess,
}: ChangeEmployeeClassFormProps) => {
  const isSingleEmployee = selectedEmployees.length === 1;
  const singleEmployee = selectedEmployees[0];

  const { executeBatchCall, errors } = useBatchCallEndpoint({
    methodName: 'employeesControllerUpdateByAdmin',
    singularItemName: 'employee',
    action: 'updated',
    batchSize: 5,
  });

  const {
    control,
    formState: { isValid },
    getValues,
  } = useForm<ChangeEmployeeClassFormFields>({
    mode: 'all',
    resolver: yupResolver(changeEmployeeClassSchema),
    defaultValues: {
      class: isSingleEmployee ? singleEmployee.class : undefined,
    },
  });

  async function handleSubmit() {
    const { class: employeeClass } = getValues();

    const batchItems = selectedEmployees.map((employee) => {
      const fullName =
        'fullName' in employee
          ? employee.fullName
          : `${employee.firstName} ${employee.lastName}`;
      return {
        key: fullName,
        params: [employee.id, { class: employeeClass }] as const,
      };
    });

    await executeBatchCall(batchItems, { onSuccess });
  }

  return (
    <>
      <Stack>
        <EmployeeClassInput
          control={control}
          employerId={employerId}
          name="class"
          selectProps={{ searchable: true }}
        />

        <ErrorsTable
          tableTitle="Employee name"
          errors={errors}
          isBulk={selectedEmployees?.length > 1}
        />
      </Stack>

      <FormFooter
        primaryLabel="Save"
        primaryButtonProps={{ onClick: handleSubmit, disabled: !isValid }}
        secondaryLabel="Cancel"
        secondaryButtonProps={{
          onClick: onClose,
        }}
      />
    </>
  );
};

export { ChangeEmployeeClassForm };
