import { IconCheck } from '@tabler/icons-react';
import {
  EmployeeDto,
  OnboardingPeriodDto,
  PaymentStatus,
} from '@zorro/clients';
import { useMonolithQuery } from '@zorro/shared/utils';
import {
  Box,
  Button,
  Card,
  Flex,
  Group,
  Icon,
  Link,
  Modal,
  Stack,
  Text,
  Title,
  Tooltip,
  brand,
} from '@zorro/zorro-ui-design';
import { ReactNode, useState } from 'react';

import { ModalFooter, ModalTitle } from '../../ModalComponents';
import { useMonolithMutation } from '../../hooks';
import { PAYMENT_SECTION_WIDTH } from '../paymentsDesign.consts';
import {
  getAutoPayStatus,
  getInitialPremiumStatus,
} from './PaymentStatusUtils';

const defaultTextAndIcon = {
  label: '-',
  icon: null,
  description: null,
  learnMoreLink: null,
  toolTipLabel: null,
  shouldShowMarkAsDone: false,
};

type EmployeePaymentStatusProps = {
  employeeId: EmployeeDto['id'];
  onboardingPeriodId: OnboardingPeriodDto['id'];
  isElena?: boolean;
  children?: ReactNode;
};

type ElenaPaymentStatusCardProps = {
  icon: JSX.Element | null;
  toolTipLabel: string | null;
  label: string;
  description: string | null;
  learnMoreLink: string | null;
  openModal: (value: boolean) => void;
  shouldShowMarkAsDone: boolean;
};

const getElenaPaymentStatusCard = ({
  icon,
  toolTipLabel,
  label,
  description,
  learnMoreLink,
  openModal,
  shouldShowMarkAsDone,
}: ElenaPaymentStatusCardProps) => {
  return (
    <Card p="xl" h="auto">
      <Stack gap="md" h="100%">
        <Stack gap="xs">
          <Group justify="space-between">
            {icon}
            {toolTipLabel && (
              <Tooltip label={toolTipLabel} position="top">
                <Box
                  bg={brand.zorroPlum100}
                  pl="xs"
                  pr="xs"
                  style={{
                    borderRadius: 4,
                  }}
                >
                  <Text size="sm">Action needed</Text>
                </Box>
              </Tooltip>
            )}
          </Group>
          <Text size="sm" fw={600}>
            {label}
          </Text>
          <Text size="sm" fw={400} c="zorroGray.4">
            {description}
          </Text>
        </Stack>
        {learnMoreLink && (
          <Link
            href={learnMoreLink}
            target="_blank"
            // eslint-disable-next-line id-length
            anchorProps={{ size: 'sm', w: 'fit-content' }}
          >
            Learn more
          </Link>
        )}
        {shouldShowMarkAsDone && (
          <Button
            variant="primary"
            leftSection={
              <Icon icon={IconCheck} size="1.25rem" color={brand.zorroWhite} />
            }
            onClick={() => openModal(true)}
            px="md"
            py="xxs"
            h="fit-content"
            w="fit-content"
          >
            <Text size="md" c={brand.zorroWhite}>
              Mark as done
            </Text>
          </Button>
        )}
      </Stack>
    </Card>
  );
};

export const EmployeePaymentStatus = ({
  employeeId,
  onboardingPeriodId,
  isElena = false,
  children,
}: EmployeePaymentStatusProps) => {
  const [isPremiumPaymentModalOpen, setIsPremiumPaymentModalOpen] =
    useState<boolean>(false);
  const [isAutoPayModalOpen, setIsAutoPayModalOpen] = useState<boolean>(false);

  const { tryMutate: updatePaymentMethodStatuses } = useMonolithMutation({
    method: 'paymentsControllerUpdatePaymentMethod',
  });

  const { isLoading, data } = useMonolithQuery({
    method: 'paymentsControllerGetPaymentMethod',
    params: [employeeId, onboardingPeriodId],
  });

  if (isLoading || !data) {
    return null;
  }

  const { initialPremiumPaymentStatus, autoPayStatus, status } = data;

  if (!autoPayStatus && !initialPremiumPaymentStatus) {
    return null;
  }

  const updatePaymentMethod = async () => {
    await updatePaymentMethodStatuses([
      employeeId,
      {
        onboardingPeriodId,
        status,
        initialPremiumPaymentStatus: isPremiumPaymentModalOpen
          ? PaymentStatus.TRUE_BY_EMPLOYEE
          : initialPremiumPaymentStatus,
        autoPayStatus: isAutoPayModalOpen
          ? PaymentStatus.TRUE_BY_EMPLOYEE
          : autoPayStatus,
      },
    ]);
    setIsPremiumPaymentModalOpen(false);
    setIsAutoPayModalOpen(false);
  };

  const {
    label: premiumPaymentLabel,
    icon: premiumPaymentIcon,
    description: premiumPaymentDescription,
    learnMoreLink: premiumPaymentLearnMoreLink,
    toolTipLabel: premiumPaymentToolTipLabel,
    shouldShowMarkAsDone: premiumPaymentShouldShowMarkAsDone,
  } = initialPremiumPaymentStatus
    ? getInitialPremiumStatus(initialPremiumPaymentStatus, isElena)
    : defaultTextAndIcon;

  const {
    label: autoPayLabel,
    icon: autoPayIcon,
    description: autoPayDescription,
    learnMoreLink: autoPayLearnMoreLink,
    toolTipLabel: autoPayToolTipLabel,
    shouldShowMarkAsDone: autoPayShouldShowMarkAsDone,
  } = autoPayStatus
    ? getAutoPayStatus(autoPayStatus, isElena)
    : defaultTextAndIcon;

  return (
    <Stack gap="sm">
      <Title order={2} size="sm">
        Payment status
      </Title>
      {isElena ? (
        <Card maw={PAYMENT_SECTION_WIDTH} p={32}>
          <Flex direction={{ base: 'column', md: 'row' }} gap="xl">
            {getElenaPaymentStatusCard({
              icon: premiumPaymentIcon,
              toolTipLabel: premiumPaymentToolTipLabel,
              label: premiumPaymentLabel,
              description: premiumPaymentDescription,
              learnMoreLink: premiumPaymentLearnMoreLink,
              openModal: setIsPremiumPaymentModalOpen,
              shouldShowMarkAsDone: premiumPaymentShouldShowMarkAsDone,
            })}
            {getElenaPaymentStatusCard({
              icon: autoPayIcon,
              toolTipLabel: autoPayToolTipLabel,
              label: autoPayLabel,
              description: autoPayDescription,
              learnMoreLink: autoPayLearnMoreLink,
              openModal: setIsAutoPayModalOpen,
              shouldShowMarkAsDone: autoPayShouldShowMarkAsDone,
            })}
          </Flex>
          {children}
        </Card>
      ) : (
        <Card maw={PAYMENT_SECTION_WIDTH}>
          <Group gap="xl" grow justify="space-between">
            <Stack>
              <Title order={3} size="sm">
                Initial premium payment
              </Title>
              <Group gap="xs">
                {premiumPaymentIcon}
                <Text size="sm">{premiumPaymentLabel}</Text>
              </Group>
            </Stack>
            <Stack>
              <Title order={3} size="sm">
                Auto-Pay
              </Title>
              <Group gap="xs">
                {autoPayIcon}
                <Text size="sm">{autoPayLabel}</Text>
              </Group>
            </Stack>
          </Group>
          {children}
        </Card>
      )}

      <Modal
        size="lg"
        opened={isPremiumPaymentModalOpen}
        onClose={() => setIsPremiumPaymentModalOpen(false)}
        showSeparator={false}
        title={
          <ModalTitle
            title="Confirm initial premium payment"
            bgIcon="zorroGreen.3"
            icon={<Icon icon={IconCheck} />}
            titleOrder={2}
          />
        }
      >
        <Text mb="xl" size="md">
          Are you sure you have completed your initial premium <br />
          payment? Confirm to update your status
        </Text>
        <ModalFooter
          onClose={() => setIsPremiumPaymentModalOpen(false)}
          onSubmit={updatePaymentMethod}
          submitVariant="brand.zorroPlum100"
          submitText="Confirm"
        />
      </Modal>
      <Modal
        size="lg"
        opened={isAutoPayModalOpen}
        onClose={() => setIsAutoPayModalOpen(false)}
        showSeparator={false}
        title={
          <ModalTitle
            title="Confirm auto pay setup"
            bgIcon="zorroGreen.3"
            icon={<Icon icon={IconCheck} />}
            titleOrder={2}
          />
        }
      >
        <Text mb="xl" size="md">
          Are you sure you have set up auto pay? Confirm to update <br />
          your status
        </Text>
        <ModalFooter
          onClose={() => setIsAutoPayModalOpen(false)}
          onSubmit={updatePaymentMethod}
          submitVariant="brand.zorroPlum100"
          submitText="Confirm"
        />
      </Modal>
    </Stack>
  );
};
