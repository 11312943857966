import { ValueOf } from '@zorro/types';
import { Center, brand } from '@zorro/zorro-ui-design';

import { SVGIconProps } from '../types';
import classNames from './Icon.module.scss';

type Props = SVGIconProps & {
  flagColor?: ValueOf<typeof brand>;
  withDot?: boolean;
};

export const FlagIcon = ({
  style,
  grow,
  withDot = false,
  flagColor = '#FFC214',
}: Props) => {
  if (withDot) {
    return (
      <Center style={style} classNames={classNames} data-grow={grow}>
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_6617_19991)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.18 6.95012C14.53 6.95012 14.88 6.99012 15.22 7.05012L17.62 4.65012C17.83 4.44012 17.9 4.11012 17.78 3.83012C17.66 3.55012 17.39 3.37012 17.09 3.37012H5.42001C5.00001 3.37012 4.67001 3.70012 4.67001 4.12012V11.6201C4.67001 12.0301 5.01001 12.3701 5.42001 12.3701H8.21001C8.50001 9.33012 11.06 6.95012 14.18 6.95012Z"
              fill="#00D16C"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.25 3.2002C5.66 3.2002 6 3.5402 6 3.9502V17.4502C6 17.8602 5.66 18.2002 5.25 18.2002C4.84 18.2002 4.5 17.8602 4.5 17.4502V3.9502C4.5 3.5402 4.84 3.2002 5.25 3.2002Z"
              fill="#1A1721"
            />
            <path
              d="M19.2 12.95C19.2 15.6881 16.9784 17.9 14.25 17.9C11.5217 17.9 9.30005 15.6783 9.30005 12.95C9.30005 10.2217 11.5217 8 14.25 8C16.9784 8 19.2 10.2217 19.2 12.95Z"
              fill="#FF5636"
            />
            <path
              d="M14.2519 13.4601C13.974 13.4601 13.7409 13.236 13.7409 12.9491V10.5109C13.7409 10.2331 13.965 10 14.2519 10C14.5387 10 14.7628 10.2241 14.7628 10.5109V12.9491C14.7628 13.227 14.5387 13.4601 14.2519 13.4601Z"
              fill="white"
            />
            <path
              d="M14.5567 15.5933C14.4402 15.665 14.3147 15.7099 14.1802 15.7099C14.001 15.7099 13.8217 15.6381 13.6962 15.5126C13.5707 15.3872 13.499 15.2079 13.499 15.0286C13.499 14.8941 13.5348 14.7597 13.6155 14.6521C13.6872 14.5356 13.7948 14.4549 13.9203 14.4011C14.0458 14.3473 14.1802 14.3384 14.3147 14.3653C14.4492 14.3922 14.5657 14.4549 14.6643 14.5535C14.7629 14.6521 14.8256 14.7686 14.8525 14.9031C14.8794 15.0376 14.8615 15.172 14.8167 15.2975C14.7629 15.423 14.6733 15.5306 14.5657 15.6023L14.5567 15.5933Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_6617_19991">
              <rect
                width="14.83"
                height="15"
                fill="white"
                transform="translate(4.5 3.2002)"
              />
            </clipPath>
          </defs>
        </svg>
      </Center>
    );
  }

  return (
    <Center style={style} classNames={classNames} data-grow={grow}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.5 4.5H4V12H15.5L12 8L14.5 4.5Z" fill="#FFBBAF" />
        <path
          d="M4.16675 11.667H15.8334L12.0834 7.91699L15.8334 4.16699H4.16675V11.667Z"
          fill={flagColor}
          stroke={flagColor}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4 4V17.5"
          stroke="#1A1721"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </Center>
  );
};
