import { IconSwitchHorizontal, IconTrash } from '@tabler/icons-react';
import { EmployerSignupStatus, Roles } from '@zorro/clients';
import { ThreeDotsIcon } from '@zorro/shared/assets';
import {
  SUCCESS_MESSAGES,
  showSuccessNotification,
  useDynamicMurrietaRouter,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  Icon,
  Modal,
  RadixMenu,
  Text,
} from '@zorro/zorro-ui-design';
import { useState } from 'react';

import { ModalFooter, ModalTitle } from '../ModalComponents';
import { useMonolithMutation } from '../hooks';

type Props = {
  employerId: string;
};

export const EmployerDotsMenu = ({ employerId }: Props) => {
  const { navigateToRoute } = useDynamicMurrietaRouter();
  const { userRoles } = useRoles();
  const [open, setOpen] = useState(false);

  const [isDeleteEmployerModalOpen, setIsDeleteEmployerModalOpen] =
    useState<boolean>(false);
  const [isChangeToProspectModalOpen, setIsChangeToProspectModalOpen] =
    useState<boolean>(false);

  const { mutate: mutateEmployer } = useMonolithMutation({
    method: 'employersControllerUpdate',
  });
  const { mutate: mutateEmployerDelete } = useMonolithMutation({
    successMessage: 'Deleted employer successfully.',
    method: 'employersControllerRemove',
  });

  const { data: employer, isLoading } = useMonolithQuery({
    method: 'employersControllerFindOne',
    params: [employerId],
  });

  const showChangeToDemoOption =
    userRoles.includes(Roles.OMNIPOTENT_ADMIN) ||
    userRoles.includes(Roles.ACCOUNT_SUPERVISOR) ||
    userRoles.includes(Roles.OPERATOR);

  const isDemoEmployer = employer?.isDemo || false;

  const handleChangeToProspect = async () => {
    try {
      await mutateEmployer([
        employerId,
        {
          zorroPartnerId: employer?.zorroPartnerId ?? null,
          producerId: employer?.producerId ?? null,
          signupStatus: EmployerSignupStatus.PROSPECT,
          isProspect: false,
        },
      ]);
      showSuccessNotification({
        message: SUCCESS_MESSAGES.SET_EMPLOYER_TO_PROSPECT_SUCCESS_MESSAGE,
      });
      setIsChangeToProspectModalOpen(false);
      navigateToRoute('COMPANY_SETUP_PROSPECT', [employerId]);
    } catch {
      /* empty */
    }
  };

  const handleToggleDemoCompany = async () => {
    try {
      const { isDemo: newIsDemo } = await mutateEmployer([
        employerId,
        {
          zorroPartnerId: employer?.zorroPartnerId ?? null,
          producerId: employer?.producerId ?? null,
          isProspect: employer?.isProspect || true,
          isDemo: !isDemoEmployer,
        },
      ]);

      showSuccessNotification({
        message: newIsDemo
          ? 'Marked as demo company'
          : 'Unmarked as demo company',
      });
    } catch {
      /* empty */
    }
  };

  const handleDeleteCompany = async () => {
    try {
      await mutateEmployerDelete([employerId]);
      if (employer?.isProspect) {
        navigateToRoute('PROSPECTS', []);
      } else {
        navigateToRoute('EMPLOYERS', []);
      }
    } catch {
      /* empty */
    }
  };

  if (!employer || isLoading) {
    return null;
  }

  return (
    <>
      <RadixMenu open={open} onOpenChange={setOpen}>
        <RadixMenu.Trigger>
          <ActionIcon isChecked={open} isTransparent>
            <ThreeDotsIcon />
          </ActionIcon>
        </RadixMenu.Trigger>

        <RadixMenu.Content style={{ width: 200 }} align="end">
          {!employer?.isProspect && (
            <RadixMenu.Item
              onClick={() => setIsChangeToProspectModalOpen(true)}
            >
              Change to prospect
            </RadixMenu.Item>
          )}
          {showChangeToDemoOption && (
            <RadixMenu.Item onClick={handleToggleDemoCompany}>
              {isDemoEmployer
                ? 'Unmark as demo company'
                : 'Mark as demo company'}
            </RadixMenu.Item>
          )}
          <RadixMenu.Item onClick={() => setIsDeleteEmployerModalOpen(true)}>
            Delete employer
          </RadixMenu.Item>
        </RadixMenu.Content>
      </RadixMenu>
      <Modal
        size="550px"
        opened={isChangeToProspectModalOpen}
        onClose={() => setIsChangeToProspectModalOpen(false)}
        styles={{}}
        showSeparator={false}
        title={
          <Flex align="center">
            <Box bg="zorroSky.1" px="6px" py="4px" style={{ borderRadius: 4 }}>
              <Icon icon={IconSwitchHorizontal} size={24} />
            </Box>

            <Text size="xl" ml="sm" fw={500}>
              Change {employer.name} to prospect ?
            </Text>
          </Flex>
        }
      >
        Are you sure you want to change this employer back to prospect mode?
        <Group mt="xl" justify="flex-end">
          <Button
            onClick={() => setIsChangeToProspectModalOpen(false)}
            variant="transparent"
            color="zorroCoal.9"
            size="md"
            fw="400"
          >
            Cancel
          </Button>
          <Button size="md" fw="400" onClick={handleChangeToProspect}>
            Change to prospect
          </Button>
        </Group>
      </Modal>
      <Modal
        size="md"
        opened={isDeleteEmployerModalOpen}
        onClose={() => setIsDeleteEmployerModalOpen(false)}
        showSeparator={false}
        title={
          <ModalTitle
            title={`Delete ${employer.name}?`}
            bgIcon="zorroFire.3"
            icon={<Icon icon={IconTrash} size={24} />}
            titleOrder={2}
          />
        }
      >
        <Text mb="xl">
          Are you sure you want to delete this employer? <br />
          This action cannot be undone
        </Text>
        <ModalFooter
          onClose={() => setIsDeleteEmployerModalOpen(false)}
          onSubmit={handleDeleteCompany}
          submitVariant="primary-red"
          submitText="Delete"
        />
      </Modal>
    </>
  );
};
