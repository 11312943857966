import { ThreeDotsIcon } from '@zorro/shared/assets';
import {
  getEmployeeEmploymentStatus,
  useMonolithQuery,
  useRoles,
} from '@zorro/shared/utils';
import {
  ActionIcon,
  Box,
  Group,
  RadixMenu,
  Stack,
  Text,
} from '@zorro/zorro-ui-design';
import { useState } from 'react';

import { EmployeeLayoutAction } from '../EmployeeLayout/EmployeeLayoutActions';
import { getEligibilityStatusBadgeConfig } from './EligibilityStatusBadgeUtils';

type Props = {
  employeeId: string;
  setAction: (action: EmployeeLayoutAction) => void;
};

export const EligibilityStatusBadge = ({ employeeId, setAction }: Props) => {
  const { isZorroOperations } = useRoles();
  const [open, setOpen] = useState(false);

  const { data: employee, isLoading } = useMonolithQuery({
    method: 'employeesControllerFindOne',
    params: [employeeId],
  });

  if (!employee || isLoading) {
    return null;
  }

  const employmentStatus = getEmployeeEmploymentStatus(
    employeeId,
    employee.eligibleFrom,
    employee.eligibleUntil,
    employee.leaveOfAbsence?.startDate,
    employee.leaveOfAbsence?.endDate
  );
  const { label, icon, description } = getEligibilityStatusBadgeConfig(
    employmentStatus,
    employee
  );

  return (
    <Group
      bg="zorroGray.10"
      p="sm"
      pb="0.75rem"
      pos="relative"
      style={{ borderRadius: 8 }}
      id="eligibilityMenu"
    >
      <Group gap="xs" wrap="nowrap" align="start" w="100%">
        {icon}
        <Stack gap="0.25rem">
          <Text size="sm">{label}</Text>
          <Text c="zorroGray.4" size="xs" style={{ textWrap: 'nowrap' }}>
            {description}
          </Text>
        </Stack>
      </Group>
      <Box pos="absolute" right="0.25rem" top="0.125rem">
        <RadixMenu open={open} onOpenChange={setOpen}>
          <RadixMenu.Trigger>
            <ActionIcon isChecked={open} isTransparent>
              <ThreeDotsIcon />
            </ActionIcon>
          </RadixMenu.Trigger>

          <RadixMenu.Content align="start" style={{ width: 200 }}>
            <RadixMenu.Item
              onClick={() => setAction(EmployeeLayoutAction.TERMINATE_EMPLOYEE)}
            >
              Terminate employee
            </RadixMenu.Item>
            <RadixMenu.Item
              onClick={() =>
                setAction(EmployeeLayoutAction.SET_LEAVE_OF_ABSENCE)
              }
            >
              Set a leave of absence
            </RadixMenu.Item>
            {isZorroOperations && (
              <RadixMenu.Item
                onClick={() =>
                  setAction(EmployeeLayoutAction.UPDATE_ELIGIBILITY)
                }
              >
                Update eligibility
              </RadixMenu.Item>
            )}
          </RadixMenu.Content>
        </RadixMenu>
      </Box>
    </Group>
  );
};
