import {
  BenefitEnrollmentDto,
  EmployeeDto,
  OnboardingPeriodDto,
  SubmissionType,
} from '@zorro/clients';
import {
  formatDateISO,
  getDateUtil,
  getNow,
  parseDateISO,
} from '@zorro/shared/formatters';

export function getWaiveCoverageEffectiveDates(
  employee?: EmployeeDto,
  onboardingPeriod?: OnboardingPeriodDto,
  benefitEnrollment?: BenefitEnrollmentDto | null,
  submissionType?: SubmissionType
): [Date | null, Date | null] {
  if (benefitEnrollment) {
    const effectiveFrom = parseDateISO(benefitEnrollment.effectiveFrom);
    const effectiveUntil = parseDateISO(benefitEnrollment.effectiveUntil);

    return [effectiveFrom.toDate(), effectiveUntil.toDate()];
  }

  if (!employee || !onboardingPeriod) {
    return [null, null];
  }

  const targetEnrollmentDateDataPoint =
    onboardingPeriod.isSpecialEnrollment &&
    onboardingPeriod.qualifyingLifeEvent &&
    (submissionType === SubmissionType.BY_EMPLOYEE ||
      submissionType === SubmissionType.JOINT_SESSION)
      ? formatDateISO(
          getDateUtil().min(
            parseDateISO(onboardingPeriod.targetEnrollmentDate),
            getNow().startOf('month').add(1, 'month')
          )
        )
      : onboardingPeriod.targetEnrollmentDate;

  const effectiveFromDataPoints = [
    targetEnrollmentDateDataPoint,
    onboardingPeriod.enrollmentEffectiveFrom,
    employee.eligibleFrom,
  ].flatMap((dataPoint) => (dataPoint ? [parseDateISO(dataPoint)] : []));

  const effectiveFrom = getDateUtil().max(effectiveFromDataPoints);

  const effectiveUntilDataPoints = [
    onboardingPeriod.enrollmentEffectiveUntil,
    employee.eligibleUntil,
  ].flatMap((dataPoint) => (dataPoint ? [parseDateISO(dataPoint)] : []));

  const effectiveUntil = getDateUtil().min(effectiveUntilDataPoints);

  if (!effectiveFrom || !effectiveUntil) {
    return [null, null];
  }

  return [effectiveFrom.toDate(), effectiveUntil.toDate()];
}
