import { DeepReadonly, EnrollmentStatus } from '@zorro/types';
import { ReactElement } from 'react';

import {
  ActiveCoverageIcon,
  CarrierApplicationSentIcon,
  CoverageEndedIcon,
  DeadlinePassedIcon,
  ElectionActiveHasNotStartedIcon,
  ElectionActiveStartedIcon,
  ElectionSubmittedIcon,
  EnrollmentConfirmedIcon,
  NoEnrollmentsIcon,
  PendingElectionWindowIcon,
  WaivedCoverageIcon,
  WaivedElectionIcon,
} from './StatusIcons';

export const enrollmentStatusConfig: DeepReadonly<{
  [key in EnrollmentStatus]: {
    label: string;
    tooltipLabel?: string;
    renderIcon: (size?: number) => ReactElement;
  };
}> = {
  [EnrollmentStatus.PENDING_ELECTION_WINDOW]: {
    tooltipLabel: 'The election period has not started yet',
    label: 'Pending election window',
    renderIcon: (size) => <PendingElectionWindowIcon size={size} />,
  },
  [EnrollmentStatus.ELECTION_ACTIVE]: {
    label: 'Election active',
    renderIcon: (size) => <ElectionActiveStartedIcon size={size} />,
  },
  [EnrollmentStatus.ELECTION_ACTIVE_HAS_NOT_STARTED]: {
    tooltipLabel:
      'The election period is active, but the employee has not logged in yet',
    label: "Election active: Hasn't started",
    renderIcon: (size) => <ElectionActiveHasNotStartedIcon size={size} />,
  },
  [EnrollmentStatus.ELECTION_ACTIVE_STARTED]: {
    tooltipLabel:
      'The election period is active, and the employee has logged in but has not made a selection',
    label: 'Election active: Started',
    renderIcon: (size) => <ElectionActiveStartedIcon size={size} />,
  },
  [EnrollmentStatus.ELECTION_SUBMITTED]: {
    tooltipLabel: 'The employee has completed their plan selection',
    label: 'Election submitted',
    renderIcon: (size) => <ElectionSubmittedIcon size={size} />,
  },
  [EnrollmentStatus.WAIVED_ELECTION]: {
    tooltipLabel: 'The employee has chosen to waive coverage',
    label: 'Waived election',
    renderIcon: (size) => <WaivedElectionIcon size={size} />,
  },
  [EnrollmentStatus.DEADLINE_PASSED]: {
    tooltipLabel:
      'The election period ended without a plan selection from the employee',
    label: 'Deadline passed',
    renderIcon: (size) => <DeadlinePassedIcon size={size} />,
  },
  [EnrollmentStatus.ENROLLMENT_CONFIRMED]: {
    tooltipLabel:
      "The employee's enrollment has been finalized and confirmed by the carrier",
    label: 'Enrollment confirmed',
    renderIcon: (size) => <EnrollmentConfirmedIcon size={size} />,
  },
  [EnrollmentStatus.CARRIER_APPLICATION_SENT]: {
    tooltipLabel:
      "The application has been submitted to the carrier. Awaiting the carrier's final confirmation.",
    label: 'Carrier application sent',
    renderIcon: (size) => <CarrierApplicationSentIcon size={size} />,
  },
  [EnrollmentStatus.COVERAGE_ENDED]: {
    tooltipLabel: "The employee's coverage under the selected plan has ended",
    label: 'Coverage ended',
    renderIcon: (size) => <CoverageEndedIcon size={size} />,
  },
  [EnrollmentStatus.ACTIVE_COVERAGE]: {
    tooltipLabel:
      "The employee's coverage under the selected plan is currently active",
    label: 'Active coverage',
    renderIcon: (size) => <ActiveCoverageIcon size={size} />,
  },
  [EnrollmentStatus.WAIVED_COVERAGE]: {
    tooltipLabel: 'The employee has chosen to waive coverage',
    label: 'Waived coverage',
    renderIcon: (size) => <WaivedCoverageIcon size={size} />,
  },
  [EnrollmentStatus.NO_ENROLLMENTS]: {
    label: 'No enrollments',
    renderIcon: (size) => <NoEnrollmentsIcon size={size} />,
  },
};
