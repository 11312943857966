import { ApiError } from '@zorro/clients';

export const UNEXPECTED_ERROR_MESSAGE =
  'Something went wrong. Please retry later or contact support if the issue persists';

export type ErrorInfo = {
  errors: string[] | Record<string, string[]>;
  statusCode?: number;
};

export type KeyedError = {
  key: string;
  error: string;
};

export type ErrorsBody = Record<string, string[]>;

export function extractErrorProperties(
  error: Record<string, unknown>
): Record<string, unknown> {
  return Object.getOwnPropertyNames(error).reduce(
    (acc: Record<string, unknown>, prop) => {
      acc[prop] = error[prop];
      return acc;
    },
    {}
  );
}

function joinErrorsArray(errors: string[]): string {
  return errors.length > 1 ? errors.join(', ') : errors[0];
}

function stringifyErrorsBody(errorsBody: ErrorsBody): string {
  const err: string[] = [];
  for (const key in errorsBody) {
    const currentErrors = errorsBody[key];
    if (currentErrors.length > 0) {
      err.push(...currentErrors);
    }
  }
  return joinErrorsArray(err);
}

export function responseErrorToString(error: unknown): string {
  try {
    // Handle string errors directly
    if (typeof error === 'string') {
      return error;
    }

    // Handle ApiError with errors in body
    if (
      error &&
      typeof error === 'object' &&
      error instanceof ApiError &&
      'errors' in error.body
    ) {
      if (Array.isArray(error.body['errors'])) {
        return joinErrorsArray(error.body['errors']);
      }
      if (
        typeof error.body['errors'] === 'object' &&
        error.body['errors'] &&
        Object.keys(error.body['errors']).length > 0
      ) {
        return stringifyErrorsBody(error.body['errors'] as ErrorsBody);
      }
    }

    // Handle standard Error objects
    if (error instanceof Error) {
      return error.message;
    }

    // Log detailed information for unexpected errors
    // eslint-disable-next-line no-console
    console.error('Unexpected error encountered:', {
      errorType: error ? typeof error : 'undefined/null',
      errorValue: error,
      errorKeys:
        error && typeof error === 'object'
          ? Object.getOwnPropertyNames(error)
          : [],
      errorToString: error?.toString?.(),
    });

    return UNEXPECTED_ERROR_MESSAGE;
  } catch (loggingError) {
    // Safely log any errors that occurred during error processing
    // eslint-disable-next-line no-console
    console.error('Error while processing error:', {
      loggingErrorMessage: loggingError?.toString?.(),
      originalError: String(error),
    });
    return UNEXPECTED_ERROR_MESSAGE;
  }
}

export function getErrorsFromPromises(
  results: PromiseSettledResult<unknown>[],
  initialData: { key: string }[]
): { key: string; error: string }[] {
  return results
    .filter((result) => result.status === 'rejected')
    .map((result, index) => {
      const { reason } = result as PromiseRejectedResult;
      return {
        key: initialData[index]?.key,
        error: responseErrorToString(reason),
      };
    });
}
