import { ReactNode, useEffect, useState } from 'react';

export function ClientSideRender({ children }: { children: ReactNode }) {
  const [csrReady, setCsrReady] = useState(false);

  // 🧠 useEffect ensures the children are rendered in the browser
  useEffect(() => {
    setCsrReady(true);
  }, []);

  return csrReady ? children : null;
}
