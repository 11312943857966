import {
  NavigationProgress,
  NavigationProgressProps,
} from '@mantine/nprogress';
import { useMurrietaRouter } from '@zorro/shared/utils';
import { useMemo, useState } from 'react';

import classNames from './NavigationProgress.module.scss';

export const ZorroNavigationProgress = ({
  stepInterval = 250,
  ...props
}: NavigationProgressProps) => {
  const { router } = useMurrietaRouter();
  const fullPath = router.asPath;

  const [prevFullPath, setPrevFullPath] = useState<string>('');

  const showProgress = useMemo(() => {
    const path = fullPath.split('?')[0];
    const prevPath = prevFullPath.split('?')[0];

    if (prevPath === path && prevFullPath !== fullPath) {
      return false;
    }
    setPrevFullPath(fullPath);

    return true;
  }, [fullPath, prevFullPath]);

  if (!showProgress) {
    return null;
  }

  return (
    <NavigationProgress
      {...props}
      className={classNames.navigationProgress}
      stepInterval={stepInterval}
    />
  );
};
