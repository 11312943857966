import { MonolithMethods } from '@zorro/shared/utils';
import { Stack, Table, Text } from '@zorro/zorro-ui-design';

import { ModalFooter } from '../../ModalComponents';
import { useBatchCallEndpoint } from '../../hooks';
import { ErrorsTable } from '../Errors/ErrorsTable';

const methodName = 'employeesControllerDelete' as const;
type BatchItemType = {
  key: string;
  params: Parameters<MonolithMethods[typeof methodName]>;
};

export type DeleteEmployeeRow = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

export type DeleteEmployeeFormProps = {
  selectedEmployees: DeleteEmployeeRow[];
  onClose: () => void;
  onSuccess: () => void;
};

export const DeleteEmployeeForm = ({
  selectedEmployees,
  onClose,
  onSuccess,
}: DeleteEmployeeFormProps) => {
  const { executeBatchCall, errors } = useBatchCallEndpoint({
    singularItemName: 'employee',
    action: 'deleted',
    batchSize: 5,
    methodName,
  });

  const deleteEmployees = async () => {
    const batchItems: BatchItemType[] = selectedEmployees.map(
      ({ id, firstName, lastName }) => ({
        key: `${firstName} ${lastName}`,
        params: [id],
      })
    );

    await executeBatchCall(batchItems, { onSuccess });
  };

  async function handleSubmit() {
    await deleteEmployees();
  }

  return (
    <Stack>
      {errors.length > 0 ? (
        <ErrorsTable
          tableTitle="Employee name"
          errors={errors}
          isBulk={selectedEmployees?.length > 1}
        />
      ) : (
        <Stack>
          <Text>
            This will permanently delete the following employees. Are you sure
            you want to continue?
          </Text>
          <Table
            columns={[
              { accessor: 'fullName' },
              { accessor: 'email' },
              { accessor: 'id' },
            ]}
            records={selectedEmployees}
          />
          <ModalFooter
            onSubmit={handleSubmit}
            onClose={onClose}
            submitText="Delete employees"
            submitVariant="primary-red"
          />
        </Stack>
      )}
    </Stack>
  );
};
